<template>
  <div>Forbidden</div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  components: {},
  setup() {
    return {};
  },
});
</script>
